<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="create-match-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="create-match-sidebar"
      :aria-expanded="openSideBar"
      width="40vw"
      @hidden="onHidden"
    >
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-card>
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-media
              no-body
              class="mt-2 mb-2"
            >
              <b-media-aside>
                <b-link>
                  <b-avatar
                    variant="primary"
                    :text="userInitials(user)"
                    :src="userProfileImage(user)"
                    size="4rem"
                  />
                </b-link>
              </b-media-aside>
              <h4 class="mt-1 ml-1">
                {{ user.full_name }}<br>
                <small class="text-muted">{{ user.email }}</small>
              </h4>
            </b-media>
            <b-form-group
              label="Search"
              label-for="Search"
            >
              <b-form-input
                id="`type-search`"
                v-model="search"
                type="search"
                placeholder="Search"
              />
            </b-form-group>
            <div v-if="isLoading">
              <aom-skeleton-loader />
            </div>
            <div v-if="matchedUsers.length === 0 && !isLoading">
              <h5 class="text-muted text-center mt-3">
                No results.
              </h5>
            </div>
            <div v-else>
              <b-list-group
                v-for="(match, index) in matchedUsers"
                :key="index"
              >
                <b-list-group-item class="d-flex align-items-center">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio
                      v-model="selectedMatch"
                      :aria-describedby="ariaDescribedby"
                      name="selected-match"
                      :value="match.id"
                      class="full-width"
                    >
                      <div class="d-flex">
                        <div class="left-flex mr-1 ml-1">
                          <b-avatar
                            variant="primary"
                            :text="userInitials(match)"
                            :src="userProfileImage(match)"
                            size="3rem"
                            class="left-flex"
                          />
                        </div>
                        <div class="right-flex">
                          <h5 class="mt-50 mb-0">
                            {{ match.full_name }}
                          </h5>
                          <p class="text-muted mb-0">
                            <small>{{ match.email }}</small>
                          </p>
                        </div>
                      </div>
                    </b-form-radio>
                  </b-form-group>
                  <b-link
                    href="#"
                    class="compare-user"
                    @click="openCompareModal(match)"
                  >
                    <div class="circle">
                      {{ match.compatibility }} %
                    </div>
                  </b-link>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-form>
          <b-row v-if="total > 0">
            <b-col sm="12">
              <aom-pagination
                :pages="total"
                :per-page="perPage"
                @page-change="loadNextPage"
              />
            </b-col>
          </b-row>
        </b-card>
      </validation-observer>
      <template #footer="{ hide }">
        <b-row
          v-if="!isLoading"
          class="ml-1 mr-1 pt-1 border-top"
        >
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="hide"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              variant="primary"
              :disabled="isDisabled"
              @click="publishMatch"
            >
              <feather-icon
                size="16"
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <b-spinner
                v-if="isUpdatingPublish"
                small
              />
              <span v-if="isUpdatingPublish"> Updating...</span>
              <span v-else>Publish</span>
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="isDisabled"
              @click="lockMatch"
            >
              <feather-icon
                size="16"
                icon="LockIcon"
                class="mr-50"
              />
              <b-spinner
                v-if="isUpdatingLock"
                small
              />
              <span v-if="isUpdatingLock"> Updating...</span>
              <span v-else>Lock</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
    <user-application-summary-modal
      ref="userAppSummary"
      :match-users="matchUsers"
      :compare-percentage="comparePercentage"
      @clear="clearModal"
    />
    <publish-match-modal
      ref="publishMatchModal"
      :matches="matchesToPublish"
      :is-create="true"
      @updated="onPublish"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm,
  BMedia,
  BMediaAside,
  BLink,
  BAvatar,
  BListGroupItem,
  BListGroup,
  BFormGroup,
  BFormRadio,
  BFormInput,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { ValidationObserver } from "vee-validate";
import matchesService from "@/services/matchesService";
import { mapGetters } from "vuex";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import {
  userRoles,
  matchStatus,
  ProgramMatches,
  userRolesDisplay,
} from "@models";
import _debounce from "lodash/debounce";
//eslint-disable-next-line
import { required } from "@validations";
import UserApplicationSummaryModal from "@/views/apps/champion/matches/UserApplicationSummaryModal.vue";
import PublishMatchModal from "./PublishMatchModal.vue";
import AomPagination from "@/@aom-core/AomPagination.vue";
export default {
  name: "CreateMatchSideBar",
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    BAvatar,
    BMedia,
    BMediaAside,
    BLink,
    AomSkeletonLoader,
    BListGroupItem,
    BListGroup,
    BFormGroup,
    BFormRadio,
    BFormInput,
    UserApplicationSummaryModal,
    PublishMatchModal,
    AomPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    reciprocalRole: {
      type: Number,
      required: true,
    },
  },
  data(vm) {
    return {
      matchesToPublish: [],
      sideBarTitle: vm.title,
      openSideBar: vm.open,
      isLoading: false,
      isUpdating: false,
      isUpdatingPublish: false,
      isUpdatingLock: false,
      matchedUsers: [],
      matchUsers: [],
      selectedMatch: undefined,
      comparePercentage: 0,
      search: "",
      total: 0,
      page: 1,
      perPage: 10,
      options: {
        chart: {
          height: 20,
          type: "radialBar",
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram", "defaultProgramId"]),
    isDisabled() {
      return this.selectedMatch === undefined;
    },
    reciprocalRoleInMatch() {
      return Number(this.role) === Number(userRoles.MENTEE)
        ? userRoles.MENTOR
        : userRoles.MENTEE;
    },
    hasMoreRecords() {
      return Boolean(this.page < this.totalPages);
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
  },
  watch: {
    open() {
      this.loadMatches();
      this.openSideBar = this.open;
    },
    title: {
      handler() {
        this.sideBarTitle = this.title;
      },
      immediate: true,
    },
    openSideBar(n) {
      if (!n) {
        this.$emit("toggleSideBar", n);
      }
    },
    search: _debounce(function () {
      this.page = 1;
      this.loadMatches();
    }, 1000),
  },
  methods: {
    loadNextPage(page) {
      this.loadMatches(page);
    },
    clearModal() {
      this.matchUsers = [];
    },
    openCompareModal(args) {
      // eslint-disable-next-line camelcase
      const { compatibility, ...match_user } = args;
      this.matchUsers = [
        {
          user: this.user,
          user_id: this.user.id,
          role_id: this.reciprocalRole,
          role: { name: userRolesDisplay[this.reciprocalRole] },
        },
        {
          user: match_user,
          user_id: match_user.id,
          role_id: this.role,
          role: { name: userRolesDisplay[this.role] },
        },
      ];
      this.comparePercentage = compatibility;
      this.$refs.userAppSummary.show();
    },
    async loadMatches(page) {
      let filter = {
        page: page || this.page,
        perPage: this.perPage,
        columnFilters: [],
      };
      if (this.search) {
        filter.columnFilters.push({
          field: "keyword",
          value: this.search,
        });
      }

      try {
        const programId = this.defaultProgramId;
        const userId = this.user.id;
        const role = this.role;
        this.isLoading = true;
        const response = await matchesService.listMatchCandidatesForUser(
          programId,
          userId,
          role,
          filter
        );
        const { data } = response;
        const { items, total } = data;
        this.matchedUsers = items;
        this.total = total;
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Error. Fetching candidates list"));
      } finally {
        this.isLoading = false;
      }
    },
    userInitials(user) {
      const firstInt = Array.from(user.first_name)[0];
      const lastInt = Array.from(user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    onHidden() {
      this.sideBarTitle = undefined;
      this.openSideBar = false;
      (this.isLoading = false),
        (this.isUpdating = false),
        (this.matchedUsers = []),
        (this.selectedMatch = undefined),
        (this.search = ""),
        this.$emit("close", true);
    },
    onPublish() {
      this.matchesToPublish = [];
      this.openSideBar = false;
      this.$store.commit(
        "programs/SET_FORCE_REFRESH_PARTICIPANT_MATCHES",
        true
      );
      this.isUpdatingPublish = false;
    },
    async publishMatch() {
      this.isUpdatingPublish = true;
      this.matchesToPublish = [
        {
          match_users: [
            { user_id: this.user.id, role_id: this.reciprocalRoleInMatch },
            { user_id: this.selectedMatch, role_id: this.role },
          ],
        },
      ];
      this.$nextTick(() => {
        this.$refs.publishMatchModal.open();
      });
    },
    async lockMatch() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to lock this match? If this is a participants last space for matching, then they will be removed from any other pools.",
          {
            title: "Lock this match?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              this.isUpdatingLock = true;
              const match = new ProgramMatches({
                status_id: matchStatus.LOCKED,
                match_users: [
                  {
                    user_id: this.user.id,
                    role_id: this.reciprocalRoleInMatch,
                  },
                  { user_id: this.selectedMatch, role_id: this.role },
                ],
              });
              await matchesService.postCreateMatch(
                this.defaultProgramId,
                match
              );
              this.openSideBar = false;
              this.$store.commit(
                "programs/SET_FORCE_REFRESH_PARTICIPANT_MATCHES",
                true
              );
              this.$toast(makeSuccessToast("Match locked successfully."));
            } catch (e) {
              this.$toast(
                makeErrorToast(
                  `Error creating match, ${e.response.data.message}`
                )
              );
              this.$log.error(e);
            } finally {
              this.isUpdatingLock = false;
            }
          }
        });
    },
    userProfileImage(user) {
      return user?.profile_image?.url || "";
    },
  },
};
</script>

<style lang="scss">
$sidebar-header-text-color: #808080;
.b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  background: #f3f3f3;
  color: $sidebar-header-text-color;

  #create-match-sidebar___title__ {
    font-size: 0.8em;
    flex: 2;
  }
}
.full-width {
  width: 100%;
}
.border-top {
  border-top: 1px solid #ccc;
}
.list-group-item {
  .form-group {
    flex: 2;
    .custom-control-label {
      width: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
.card {
  box-shadow: none;
}
</style>
